.spinner {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
}

.spinner div {
  position: absolute;
  box-sizing: border-box;
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--border-color);
  border-color: var(--border-color) transparent transparent transparent;
  border-radius: 50%;
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}

.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}

.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
