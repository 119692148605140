.input {
  width: 100%;
  padding: spacing(0, 2);
  padding-top: spacing(2.5);
  padding-bottom: spacing(1);
  font-family: var(--font);
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0);
  border: none;

  &:focus {
    outline: none;
  }
}
