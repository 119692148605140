.items {
  margin-bottom: spacing(6);
}

.sum {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: spacing(2);
}
