/**
  Globally accessible variables
  PostCSS transforms these to be backwards compatible.
*/

:root {
  /* colors */
  --primary: #000000;
  --primary-dim: #1f1f1f;
  --secondary: #d1dbcb;
  --secondary-dim: #c0caba;

  --yellow: #efeb84;
  --red: #e45555;
  --dark-grey: #333333;
  --mid-grey: #666666;
  --light-grey: #cccccc;
  --off-white: #f3f4ef;

  --primary-contrast: white;
  --secondary-contrast: black;
  --tertiary-contrast: white;

  /* fonts */
  --font: 'Riviera Nights', 'Avenir', geometric, sans-serif;

  /* site gutters */
  --content-min-width: 280px;
  --content-max-width: 1800px;
  --sideMargin: 24px;
  --sideMarginSmall: 16px;

  /* spacings */
  --gap-small: 24px;
  --gap: 40px;
  --gap-big: 50px;
  --block-spacing: 3.5rem;

  /* basic font sizes */

  /* z-indexes */
  --z-nav: 3000;
  --z-nav-black-overlay: 3001;
  --z-nav-drawer: 3002;
  --z-cart-drawer: 90001;
  --z-cart-drawer-black-overlay: 90000;
  --z-top-search: 3001;
  --z-backdrop: 8000;
  --z-cookiebar: 8999;
  --z-dialog: 9000;

  /* borders */
  --border-size: 1px;
  --border-color: #d9d9d9;

  /* transitions */
  --drawer-transition: transform 0.25s ease-in-out;

  /* misc */
  --scroll-y: 0;
  --nav-min-height: 150;
  --nav-max-height: 150;
  --nav-diff-height: calc(var(--nav-max-height) - var(--nav-min-height));
  --nav-height: calc((var(--nav-max-height) - min(var(--nav-diff-height), var(--scroll-y))) * 1px);
  --dim: 0.85;
  --disable-dim: 0.5;

  @media screen and ($tablet-landscape-down) {
    --nav-min-height: 120;
    --nav-max-height: 120;
  }
}
