@font-face {
  font-family: 'Riviera Nights';
  font-style: normal;
  font-weight: 200;
  font-stretch: normal;
  src:
    url('/fonts/RivieraNights-Ultralight-WebXL.woff2') format('woff2'),
    url('/fonts/RivieraNights-Ultralight-WebXL.woff') format('woff');
  font-display: auto;
}

@font-face {
  font-family: 'Riviera Nights';
  font-style: italic;
  font-weight: 200;
  font-stretch: normal;
  src:
    url('/fonts/RivieraNights-UltralightItalic-WebXL.woff2') format('woff2'),
    url('/fonts/RivieraNights-UltralightItalic-WebXL.woff') format('woff');
  font-display: auto;
}

@font-face {
  font-family: 'Riviera Nights';
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  src:
    url('/fonts/RivieraNights-Light-WebXL.woff2') format('woff2'),
    url('/fonts/RivieraNights-Light-WebXL.woff') format('woff');
  font-display: auto;
}

@font-face {
  font-family: 'Riviera Nights';
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
  src:
    url('/fonts/RivieraNights-LightItalic-WebXL.woff2') format('woff2'),
    url('/fonts/RivieraNights-LightItalic-WebXL.woff') format('woff');
  font-display: auto;
}

@font-face {
  font-family: 'Riviera Nights';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  src:
    url('/fonts/RivieraNights-Regular-WebXL.woff2') format('woff2'),
    url('/fonts/RivieraNights-Regular-WebXL.woff') format('woff');
  font-display: auto;
}

@font-face {
  font-family: 'Riviera Nights';
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  src:
    url('/fonts/RivieraNights-RegularItalic-WebXL.woff2') format('woff2'),
    url('/fonts/RivieraNights-RegularItalic-WebXL.woff') format('woff');
  font-display: auto;
}

@font-face {
  font-family: 'Riviera Nights';
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  src:
    url('/fonts/RivieraNights-Medium-WebXL.woff2') format('woff2'),
    url('/fonts/RivieraNights-Medium-WebXL.woff') format('woff');
  font-display: auto;
}

@font-face {
  font-family: 'Riviera Nights';
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
  src:
    url('/fonts/RivieraNights-MediumItalic-WebXL.woff2') format('woff2'),
    url('/fonts/RivieraNights-MediumItalic-WebXL.woff') format('woff');
  font-display: auto;
}

@font-face {
  font-family: 'Riviera Nights';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  src:
    url('/fonts/RivieraNights-Bold-WebXL.woff2') format('woff2'),
    url('/fonts/RivieraNights-Bold-WebXL.woff') format('woff');
}

@font-face {
  font-family: 'Riviera Nights';
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
  src:
    url('/fonts/RivieraNights-BoldItalic-WebXL.woff2') format('woff2'),
    url('/fonts/RivieraNights-BoldItalic-WebXL.woff') format('woff');
}

@font-face {
  font-family: 'Riviera Nights';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  src:
    url('/fonts/RivieraNights-Black-WebXL.woff2') format('woff2'),
    url('/fonts/RivieraNights-Black-WebXL.woff') format('woff');
}

@font-face {
  font-family: 'Riviera Nights';
  font-style: italic;
  font-weight: 900;
  font-stretch: normal;
  src:
    url('/fonts/RivieraNights-BlackItalic-WebXL.woff2') format('woff2'),
    url('/fonts/RivieraNights-BlackItalic-WebXL.woff') format('woff');
}
