.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-size: 1rem;

  legend {
    margin-bottom: spacing(2);
    font-weight: 450;
  }

  & + & {
    margin-top: spacing(1);
  }

  &[aria-disabled='true'] {
    opacity: 0.6;
  }
}
