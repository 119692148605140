.menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-nav-drawer);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 448px;
  height: 100%;
  pointer-events: none;
  background: #fff;
  transition: var(--drawer-transition);
  transform: translateX(-100%);
  will-change: transform;

  @media screen and ($phone-down) {
    max-width: none;
  }

  &.shown {
    pointer-events: all;
    transform: none;
  }
}

.backDrop {
  z-index: var(--z-nav-black-overlay);
  cursor: pointer;
}

.inner {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: spacing(0, 4);
  padding-bottom: spacing(2.5);
  overflow: auto;
}

.header {
  flex: none;
  padding: spacing(3, 0);
  margin-left: spacing(-1);
}

.divider {
  max-width: 200px;
  height: var(--border-size);
  margin: spacing(1, 0);
  background: black;
}

.content {
  flex: 1;
  margin-top: spacing(8);

  @media screen and ($tablet-landscape-down) {
    margin-top: spacing(4);
  }
}

.innerFooter {
  display: flex;
  align-items: center;
  justify-content: end;
}

.item {
  padding: spacing(1, 0);

  .button {
    padding: spacing(0);
  }
}

.itemText {
  color: black;
}
