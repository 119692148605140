.wrapper {
  &.block {
    width: 100%;
  }
}

.inputWrapper {
  position: relative;
  border: solid 1px var(--border-color);
  border-radius: 4px;

  &.error {
    border-color: var(--red);
  }
}

.errorWrapper {
  padding-left: spacing(2);

  fieldset & {
    height: 20px;
  }
  margin-top: spacing(0.25);
}

.errorText {
  margin: 0;
  font-size: 0.625rem;
  color: var(--red);
}

.placeholder {
  position: absolute;
  top: 50%;
  left: 16px;
  font-size: 16px;
  pointer-events: none;
  transition:
    top 0.2s,
    transform 0.2s,
    font-size 0.2s;
  transform: translateY(-50%);

  .error & {
    color: var(--red);
  }

  &.active {
    top: 4px;
    font-size: 0.625rem;
    transform: translateY(0%);
  }
}
