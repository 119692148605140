.root {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  padding: spacing(5, 0);
  padding-top: spacing(3);
}

.headerTitle {
  flex: 1;
  justify-content: center;

  h2 {
    margin: 0;
    text-align: center;
  }
}
