.root {
  position: relative;
}

.inner {
  position: relative;
  display: flex;
  gap: spacing(2);
  align-items: stretch;
  padding: spacing(1, 0);
  border-bottom: var(--border-size) solid var(--border-color);
}

.brand {
  text-transform: uppercase;
}

.name {
  color: var(--mid-grey);
  text-transform: uppercase;
}

.image {
  width: 48px;
  height: 72px;
  background-position: center;
  background-size: cover;
}

.pushApart {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.middle {
  flex: 1;
  height: calc(100% - spacing(0.5));
}

.right {
  align-items: flex-end;
}

.label {
  margin-top: spacing(1);
}

.removeButton {
  position: relative;
  display: flex;
  gap: spacing(1.5);
  align-items: center;
  padding: spacing(0);

  .xIcon {
    width: 10px;
    height: 10px;
  }

  &:hover,
  &:active {
    opacity: 0.8;
  }
}

.removeButtonText {
  font-family: var(--font);
  font-size: 0.75rem;
  color: black;
}
