.layoutContent {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--nav-height));

  main {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 32px;
    background-color: var(--off-white);

    @media screen and ($tablet-landscape-down) {
      padding: 0;
    }
  }
}
