.root {
  position: sticky;
  top: 0;
  z-index: var(--z-nav);
  background: var(--off-white);
  transition:
    height 0.25s,
    background-color 0.25s;
}

.root.secondary {
  background: var(--secondary);
}

.logo {
  flex: 1;
  padding: spacing(2);

  @media screen and ($tablet-landscape-down) {
    padding: spacing(1);
  }
}

.content {
  display: flex;
  justify-content: space-between;
  height: var(--nav-height);

  @media screen and ($tablet-landscape-down) {
    padding: spacing(1, 2);
  }
}

.seg {
  position: relative;
  height: 100%;

  &.edgeSegments {
    display: flex;
    flex: 1;
    align-items: center;
    height: max-content;
    padding: spacing(1, 0);
    transition: padding 0.25s;

    @media screen and ($tablet-landscape-down) {
      flex: 0;
      padding: 0;
    }

    &:last-child {
      gap: spacing(1);

      @media screen and ($tablet-landscape-down) {
        gap: spacing(0);
      }
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.items {
  display: flex;
  flex: 1;
  flex-flow: row wrap;
  gap: spacing(4);
  max-height: 25px;
  margin-left: spacing(4);
  overflow-y: hidden;

  @media screen and ($tablet-landscape-down) {
    display: none;
  }
}

.shoppingBagIcon {
  transform: translateY(-1px);
}

.notification {
  position: absolute;
  right: spacing(1);
  bottom: spacing(1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  font-size: 0.5rem;
  color: white;
  pointer-events: none;
  background: black;
  border-radius: 50%;
  transform: translate(30%, 40%);
}
