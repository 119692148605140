.button {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: spacing(1);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  svg {
    margin: 0 auto;
  }

  svg path {
    fill: black;
  }

  &:not(:disabled):hover,
  &:disabled {
    opacity: 0.7;
  }
}

.dummyButton {
  visibility: hidden;
}
