.root {
  width: 100%;
  min-width: var(--content-min-width);
  max-width: var(--content-max-width);
  margin: 0 auto;
}

.fullHeight {
  height: 100%;
  /* min-height: calc(100vh - var(--navHeight)); */
}

.padding-regular {
  padding: 0 var(--sideMargin);

  @media ($tablet-landscape-down) {
    padding: 0 var(--sideMarginSmall);
  }
}

.padding-small {
  max-width: 1400px;
  padding: var(--sideMargin);

  @media ($tablet-landscape-down) {
    padding: 0 var(--sideMarginSmall);
  }
}

.padding-tight {
  max-width: calc(640px + var(--sideMargin) * 2);
  padding: var(--sideMargin);

  @media ($tablet-landscape-down) {
    padding: 0 var(--sideMarginSmall);
  }
}

.padding-contained-regular {
  padding: 0 var(--sideMargin);

  @media screen and ($tablet-landscape-down) {
    padding: spacing(0);
  }
}

.full {
  max-width: auto;
}
