.root {
  display: flex;
  padding: spacing(1, 0);
  background: var(--secondary);

  > .summary::after {
    content: 'Läs mer';
  }

  &[open] > .summary::after {
    content: 'Läs mindre';
  }

  &[open] > .summary {
    margin-bottom: spacing(2);
  }

  > * {
    min-width: var(--content-min-width);
    max-width: var(--content-max-width);
    padding: 0 var(--sideMargin);
    margin: 0 auto;
  }
}

.summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  font-weight: 900;
  line-height: 16px;
  text-transform: uppercase;
}

.summary::marker {
  content: none;
}

.summary::-webkit-details-marker {
  display: none;
}

.summary::after {
  text-decoration-line: underline;
  cursor: pointer;
}

.title {
  text-transform: uppercase;
}

.subtitle {
  a {
    text-decoration: underline;

    /* Should work in most browsers, if it doesn't it's not a big deal */
    text-decoration-thickness: 0.5px;
  }
}
