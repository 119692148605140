.wrapper {
  position: relative;
  padding-bottom: spacing(8);
}

.button {
  margin-top: spacing(6);
}

.hide {
  display: none;
}

.ingridFadedInformation {
  position: absolute;
  top: 25px;
  left: 50%;
  z-index: 2;
  display: block;
  height: 100px;
  color: red;
  transform: translateX(-50%);
}

.ingridWidgetFaded {
  opacity: 0.3;
}

.spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
