.form {
  z-index: 3;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 0.875rem;
}

.title {
  margin-bottom: 20px;
  font-weight: 500;
}

.input {
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  margin-right: spacing(1);
  border: 0;
}

.message {
  &[data-color='success'] {
    color: var(--primary);
  }

  &[data-color='error'] {
    color: var(--red);
  }
  margin-top: spacing(2);
}

.inputWithButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 2rem;
  padding-bottom: spacing(1);
  margin-top: spacing(4);
  border-bottom: 1px solid var(--primary);

  .button {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    border: 0;
  }

  .button:disabled {
    color: var(--light-grey);
    cursor: not-allowed;
  }
}
