.label {
  display: inline-block;
  padding: spacing(0.5);
  font-family: var(--font);
  font-size: 0.75rem;
  font-weight: 800;

  &.yellow {
    background: var(--yellow);
  }

  &.red {
    background: var(--red);
  }
}
