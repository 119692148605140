.wrapper {
  position: relative;
  height: stretch;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.top {
  display: flex;
  flex-direction: column;
  gap: spacing(2);
  padding: spacing(0, 4);

  @media screen and ($tablet-landscape-down) {
    padding: spacing(0, 1);
  }
}

.arrowButton {
  color: var(--mid-grey);

  svg path {
    fill: var(--mid-grey);
  }
}

.spinnerCont {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
}

.pageWrapper {
  &.error {
    overflow-y: hidden;
    pointer-events: none;
    user-select: none;
    opacity: 0.5;
  }
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.cartErrorWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  padding: spacing(0, 4);
  background-color: white;

  @media screen and ($tablet-landscape-down) {
    padding: spacing(0, 1);
  }
}

.cartErrorText {
  margin-bottom: spacing(2);
}

.pages {
  display: flex;
  width: calc(100% * 3);
  overflow: hidden;
  transition:
    0.25s transform,
    0.25s height;

  .page {
    flex: 1;
    height: max-content;
    padding: spacing(0, 4);

    @media screen and ($tablet-landscape-down) {
      padding: spacing(0, 1);
    }
  }
}
