.logo {
  position: relative;
  height: 100%;
  overflow: hidden;

  & > svg {
    width: 100%;
    height: 100%;
  }
}
