.block {
  display: block;
}

/* stylelint-disable plugin/no-unused-selectors */

.align-inherit {
  text-align: inherit;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

/* stylelint-enable plugin/no-unused-selectors */
