.root {
  display: inline-block;
  padding: calc(0.75rem - 2px) calc(1.5rem - 2px);
  font-family: var(--font);
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5em;
  cursor: pointer;
  border: 2px solid purple; /* color is overwritten in the color class */

  /* something very high so there'll never be problems */
  border-radius: 1000000px;

  &:disabled,
  &[aria-disabled='true'] {
    color: var(--mid-grey);
    cursor: not-allowed;
    background-color: var(--border-color);
    border-color: var(--border-color);

    &:hover {
      opacity: initial;
    }
  }
}

.block {
  display: block;
  width: 100%;
}

.size-small {
  font-size: 0.875rem;
  font-weight: 700;
}

.size-wider {
  width: 100%;
  max-width: 340px;
}

.size-full {
  width: 100%;
}

/* stylelint-disable plugin/no-unused-selectors */
.color-primary {
  color: var(--primary-contrast);
  background-color: var(--primary);
  border-color: var(--primary);

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    text-decoration: none;
    background-color: var(--primary-dim);
    border-color: var(--primary-dim);
  }
}

.color-secondary {
  color: var(--secondary-contrast);
  background-color: var(--secondary);
  border-color: var(--secondary);

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    text-decoration: none;
    background-color: var(--secondary-dim);
    border-color: var(--secondary-dim);
  }
}

.color-tertiary {
  color: var(--primary);
  /* since the background is transparent, we can't use the primary css variable here 😔 */
  background-color: rgba(0, 0, 0, 0);
  border-color: var(--primary);

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    color: var(--primary-contrast);
    background-color: var(--primary);
  }
}
/* stylelint-enable plugin/no-unused-selectors */
