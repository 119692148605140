.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-backdrop);
  background: rgba(0, 0, 0, 0.25);
  transition: opacity 250ms ease-out;
}

.visible {
  opacity: 1;
  transition: opacity 250ms ease-out;
}

.hidden {
  opacity: 0;
  transition: opacity 250ms ease-out;
}

.exitDone {
  display: none;
  transition: opacity 250ms ease-out;
}
