.footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 128px;
  width: 100%;
  padding: 64px 80px;

  @media screen and ($tablet-landscape-down) {
    flex-direction: column;
    gap: 64px;
    padding: 32px;
  }
}

.logo {
  width: 70px;
  height: 70px;

  @media screen and ($tablet-landscape-down) {
    display: none;
  }
}

.categories {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 45px 64px;

  @media screen and ($tablet-landscape-down) {
    flex-direction: column;
    gap: 48px;
  }
}

.category {
  display: flex;
  flex-direction: column;
  gap: 20px;
  white-space: nowrap;

  > h2 {
    font-weight: 500;
  }
}

.newsletter {
  flex: 1;
  min-width: 300px;
  max-width: 600px;
}

.categoryLinks {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.link {
  font-weight: 400;
  cursor: pointer;
}

.item {
  display: contents;
}
