.menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: var(--z-cart-drawer);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 448px;
  height: 100%;
  pointer-events: none;
  background: #fff;
  transition: var(--drawer-transition);
  transform: translateX(100%);
  will-change: transform;

  @media screen and ($phone-down) {
    max-width: none;
  }

  &.shown {
    pointer-events: all;
    transform: none;
  }
}

.backDrop {
  z-index: var(--z-cart-drawer-black-overlay);
  cursor: pointer;
}

.inner {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
}

.header {
  display: flex;
  flex: none;
  justify-content: flex-end;
  padding: spacing(3, 3);

  @media screen and ($tablet-landscape-down) {
    padding: spacing(4, 0);
  }
}

.divider {
  height: var(--border-size);
  margin: spacing(1, 0);
  background: var(--border-color);
}

.innerFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item {
  padding: spacing(1, 0);

  .button {
    padding: spacing(0);
  }
}

.itemText {
  color: black;
}

.emptyCartCont {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
}
