/**
This file contains global non-modularized css classes
*/

/* Typography */

.center {
  text-align: center;
}

p,
.p {
  font-family: var(--font);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

a,
.a {
  font-family: var(--font);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  &:hover {
    text-decoration: underline;
  }
}

b,
.b,
strong,
.strong {
  font-family: var(--font);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

/* li, */
/* .li { */
/*   font-family: 'source-sans-pro', sans-serif; */
/*   font-size: 14px; */
/*   font-style: normal; */
/*   font-weight: 700; */
/*   line-height: 150%; */
/* } */

h1,
.h1-light,
.h1 {
  font-family: var(--font);
  font-size: 1.5rem;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
}

.h1-light {
  font-family: var(--font);
}

h2,
.h2-light,
.h2 {
  font-family: var(--font);
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
}

.h2-light {
  font-family: var(--font);
}

h3,
.h3 {
  font-family: var(--font);
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
}

h4,
.h4 {
  font-family: var(--font);
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  color: var(--mid-grey);
}
